import { Row, Column, Carousel } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Shelves } from '../Shelves'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import HeadDetail from '../HeadDetail'
import styles from '../ProductsContainer/ProductsContainer.module.css'

interface ProductAccordionProps {
  children: React.ReactNode
  dataCart: ICart
  headTitle: string
  isLoadingByEvent: boolean
  isProductsWithIssues?: boolean
  itemsAmount: number
  openCollapse: boolean
  totalQuantity: number
  handleCollapse: (open: boolean) => void
  setLimitOnProductAlert: (value: boolean) => void
}

export const ProductAccordion = ({
  children,
  dataCart,
  headTitle,
  isLoadingByEvent,
  isProductsWithIssues = false,
  itemsAmount,
  openCollapse,
  totalQuantity,
  handleCollapse,
  setLimitOnProductAlert
}: ProductAccordionProps): React.ReactElement => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: itemsAmount,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 1023, min: 0 },
      items: itemsAmount,
      slidesToSlide: 1
    }
  }

  return (
    <Column
      border={
        !openCollapse &&
        `0.6px solid ${getGlobalStyle('--color-neutral-gray-divider')}`
      }
      borderRadius={getGlobalStyle('--border-radius-md')}
      margin={isProductsWithIssues ? '8px 0 0 0' : '0'}
    >
      <HeadDetail
        headTitle={headTitle}
        onClickCollapse={() => {
          return handleCollapse(!openCollapse)
        }}
        openCollapse={openCollapse}
        showIconCollapse
        totalQuantity={totalQuantity}
      />
      <Row tagName='section'>
        <Column
          id={getBemId(
            isProductsWithIssues ? 'productsIssues' : 'products',
            'container'
          )}
          tagName='section'
        >
          {!openCollapse && (
            <Carousel
              arrows={false}
              autoPlay={false}
              containerClass={styles['wrapper__carouselContainer']}
              containerProps={{
                borderRadius: 'initial',
                maxHeight: '100%',
                maxWidth: '100%'
              }}
              infinite={false}
              partialVisbile
              responsive={responsive}
              shouldResetAutoplay={false}
              showDots={false}
              ssr
            >
              {children}
            </Carousel>
          )}
          {openCollapse && (
            <Shelves
              cartData={dataCart}
              isLoadingByEvent={isLoadingByEvent}
              isProductsWithIssues={isProductsWithIssues}
              setLimitOnProductAlert={setLimitOnProductAlert}
            />
          )}
        </Column>
      </Row>
    </Column>
  )
}
