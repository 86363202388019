import { useEffect, useState } from 'react'
import {
  Container,
  Column,
  ModalBottomSheet,
  AddToListModal
} from '@smu-chile/pkg-unimarc-components'
import { BodyTitle } from './BodyTitle'
import { ProductsTotalizers } from './ProductsTotalizers'
import { EmptyCart } from './EmptyCart'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  currentOrderformInfoTag,
  isValidArrayWithData,
  updateTagOrderForm,
  useClearOrderFormNewApi,
  useContentful,
  useEvents,
  useMobile,
  useNewCartUnimarc,
  useOrderForm
} from '@smu-chile/pkg-unimarc-hooks'
import { ModalMessageItem } from 'components/ModalMessage'
import { ContentModalCleanCart } from './BodyTitle/ContentModalCleanCart'
import { ADDRESS_ICONS, IMG_CLEAN_CART } from './helper/constants'
import { useAddProductToList } from 'shared/utils/hooks'
import { CartFailed } from './CartFailed'
import { MyUsualsAndFavoritesWrapper } from 'components/MyUsualsAndFavoritesWrapper'
import { useMyUsualsAndFavorites } from 'shared/hooks/useMyUsualsAndFavorites'
import stylesBody from './Body.module.css'

let analyticsOneLoad = false

export const Body = (): React.ReactElement => {
  const site = 'Unimarc'
  const [open, setOpen] = useState(false)
  const [isLoadingByEvent, setIsLoadingByEvent] = useState(false)
  const [productNote, setProductNote] = useState({
    open: false,
    item: {} as ModalMessageItem
  })
  const [openMyUsuals, setOpenMyUsuals] = useState(false)
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const { data, isLoading: isLoadingOrderForm } = useOrderForm()
  const cartData = data?.data
  const { mutate } = useClearOrderFormNewApi()
  const {
    data: newCart,
    isLoading: isLoadingCart,
    refetch: refetchCard
  } = useNewCartUnimarc()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseModal = () => {
    setOpenMyUsuals(false)
  }

  const imgGarlic = useContentful({
    id_contentful: 'contentful-image-clean-cart-garlic',
    options: {
      'sys.id': IMG_CLEAN_CART.imgGarlic
    },
    type: 'assets'
  })

  const imgOnion = useContentful({
    id_contentful: 'contentful-image-clean-cart-onion',
    options: {
      'sys.id': IMG_CLEAN_CART.imgOnion
    },
    type: 'assets'
  })

  const imgDonuts = useContentful({
    id_contentful: 'contentful-image-cart-failed',
    options: {
      'sys.id': IMG_CLEAN_CART.imgDonuts
    },
    type: 'assets'
  })

  const { data: dataCFImgStore } = useContentful({
    id_contentful: 'contentful-icon-address-store',
    options: {
      'sys.id': ADDRESS_ICONS.imgStore
    },
    type: 'assets'
  })

  const { data: dataCFImgByke } = useContentful({
    id_contentful: 'contentful-icon-address-byke',
    options: {
      'sys.id': ADDRESS_ICONS.imgByke
    },
    type: 'assets'
  })

  let garlicImage: string =
    imgGarlic?.data?.['items'][0]?.fields?.file?.url || ''
  let onionImage: string = imgOnion?.data?.['items'][0]?.fields?.file?.url || ''
  let donutsImage: string =
    imgDonuts?.data?.['items'][0]?.fields?.file?.url || ''

  garlicImage = `https:${garlicImage}`
  onionImage = `https:${onionImage}`
  donutsImage = `https:${donutsImage}`

  const srcImageOrderStore =
    dataCFImgStore?.['items'][0]?.fields?.file?.url || ''
  const srcImageOrderByke = dataCFImgByke?.['items'][0]?.fields?.file?.url || ''

  const objetSrcImagesIconAddress = {
    imgStore: srcImageOrderStore ?? '',
    imgByke: srcImageOrderByke ?? ''
  }

  useEvents({
    eventType: 'myUsuals',
    callBack: ({ detail: { show } }) => {
      setOpenMyUsuals(show)
    }
  })

  useEvents({
    eventType: 'clearCart',
    callBack: ({ detail: { show, error } }) => {
      setOpen(show)
      if (!show) setIsLoadingBtn(false)
      if (!error) refetchCard()
    }
  })

  useEvents({
    eventType: 'productNote',
    callBack: ({ detail: { show, item } }) => {
      setProductNote({
        ...productNote,
        item,
        open: show
      })
    }
  })

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading } }) => {
      setIsLoadingByEvent(loading)
    }
  })

  const {
    createListButton,
    emptyOrangeImage,
    listData,
    notificationAddToList,
    openModalCreateList,
    selectedListName,
    handleAddProductsToList,
    handleCreateNewList,
    handleOnClosed,
    handleOpenMyListsModal,
    setCreateListButton,
    setNotificationAddToList,
    setOpenModalCreateList
  } = useAddProductToList()

  const {
    handleChancePage,
    handleGetMyUsualsAndFavorites,
    isLoading: isLoadingMyUsualsAndFavorites,
    myUsualsAndFavoritesData
  } = useMyUsualsAndFavorites()

  const handleEmptyCard = () => {
    setIsLoadingBtn(true)
    mutate()
  }

  useEffect(() => {
    if (!cartData) return

    updateTagOrderForm({ orderForm: cartData || { noSession: true } })
  }, [data])

  useEffect(() => {
    if (cartData && cartData?.orderFormId && analyticsOneLoad === false) {
      currentOrderformInfoTag({
        event: 'view_cart',
        site
      })
      analyticsOneLoad = true
    }
  }, [cartData])

  if (newCart?.error) {
    return <CartFailed donutsImage={donutsImage} />
  }

  if (
    !isLoadingCart &&
    !isValidArrayWithData(newCart?.products) &&
    !isValidArrayWithData(newCart?.productsIssues)
  ) {
    return <EmptyCart onionImage={onionImage} />
  }

  return (
    <Container
      alignItems='start'
      backgroundColor={getGlobalStyle('--color-base-white')}
      customClassName={stylesBody.customHeight}
      id='testthis'
      justifyContent='center'
      tagName='main'
    >
      {open && (
        <ModalBottomSheet
          id={getBemId('emptyCartModal', 'container')}
          isLoadingBtn={isLoadingBtn}
          isMobile={isMobile}
          isOpen={true}
          minHeightModal='457px'
          minHeightModalMobile='576px'
          onClose={handleClose}
          primaryBtnLabel='Sí, vaciar'
          primaryCTA={() => {
            return handleEmptyCard()
          }}
          secondaryBtnLabel='No, gracias'
          secondaryCTA={handleClose}
          widthBtn={isMobile ? '343px' : '408px'}
        >
          <ContentModalCleanCart
            garlicImage={garlicImage}
            isMobile={isMobile}
          />
        </ModalBottomSheet>
      )}

      {openMyUsuals && (
        <MyUsualsAndFavoritesWrapper
          favorites={myUsualsAndFavoritesData?.favorites}
          handleChancePage={handleChancePage}
          handleClose={handleCloseModal}
          isLoadingMyUsualsAndFavorites={isLoadingMyUsualsAndFavorites}
          isLoadingOrderForm={isLoadingByEvent}
          myUsuals={myUsualsAndFavoritesData?.myUsuals}
          orderformData={data?.data}
        />
      )}

      <AddToListModal
        createListButton={createListButton}
        emptyOrangeImage={emptyOrangeImage}
        handleAddProductsToList={handleAddProductsToList}
        handleCreateNewList={handleCreateNewList}
        innerWidth={innerWidth}
        isMobile={isMobile}
        listData={listData}
        modalTitle='Guardar como lista'
        openModalCreateList={openModalCreateList}
        setCreateListButton={setCreateListButton}
        setOpenModalCreateList={setOpenModalCreateList}
      />

      <BigScreen>
        <Container
          customWidth='90'
          isWrap
          maxWidth='1160px'
          tagName='section'
        >
          <ProductsTotalizers
            cartData={newCart}
            createListButton={createListButton}
            handleGetMyUsualsAndFavorites={handleGetMyUsualsAndFavorites}
            handleOnClosed={handleOnClosed}
            handleOpenMyListsModal={handleOpenMyListsModal}
            isLoadingByEvent={isLoadingByEvent}
            isLoadingCart={isLoadingCart}
            isLoadingOrderForm={isLoadingOrderForm}
            notificationAddToList={notificationAddToList}
            objetSrcImagesIconAddress={objetSrcImagesIconAddress}
            openMyUsuals={openMyUsuals}
            selectedListName={selectedListName}
            setNotificationAddToList={setNotificationAddToList}
          />
        </Container>
      </BigScreen>

      <SmallScreen>
        <Column
          isWrap
          padding='0'
        >
          <BodyTitle
            handleOpenMyListsModal={handleOpenMyListsModal}
            isLoading={isLoadingByEvent || isLoadingCart || isLoadingOrderForm}
          />
          <ProductsTotalizers
            cartData={newCart}
            createListButton={createListButton}
            handleGetMyUsualsAndFavorites={handleGetMyUsualsAndFavorites}
            handleOnClosed={handleOnClosed}
            handleOpenMyListsModal={handleOpenMyListsModal}
            isLoadingByEvent={isLoadingByEvent}
            isLoadingCart={isLoadingCart}
            isLoadingOrderForm={isLoadingOrderForm}
            notificationAddToList={notificationAddToList}
            objetSrcImagesIconAddress={objetSrcImagesIconAddress}
            openMyUsuals={openMyUsuals}
            selectedListName={selectedListName}
            setNotificationAddToList={setNotificationAddToList}
          />
        </Column>
      </SmallScreen>
    </Container>
  )
}
