import {
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { ShelfDeleteItem } from '../Shelves/Shelf/ShelfDeleteItem'
import { ProductDetails } from '../Shelves/Shelf/ProductDetails'
import { ICartItemIssue } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'

interface NotAvailableShelvesProps {
  bemId: string
  isLoadingByEvent: boolean
  isMobile?: boolean
  orderItem: ICartItemIssue
}

export const NotAvailableShelves = ({
  bemId,
  isLoadingByEvent,
  isMobile,
  orderItem
}: NotAvailableShelvesProps): React.ReactElement => {
  const deleteItem = () => {
    return
  }

  return (
    <Row>
      <Container
        id={bemId}
        isWrap
        minWidth='100%'
        padding={isMobile ? '8px' : '12px 16px'}
      >
        <BigScreen>
          <Column>
            <ShelfDeleteItem
              isLoading={isLoadingByEvent}
              isMobile={false}
              onDelete={deleteItem}
            />
            <Spacer.Horizontal customSize={3} />
          </Column>
        </BigScreen>
        <Row>
          <ProductDetails
            img={orderItem?.image}
            isMobile={isMobile}
            isNotAvailableProduct={true}
            leftQuantity={orderItem?.leftQuantity}
            name={orderItem?.name}
          />
        </Row>
      </Container>
    </Row>
  )
}
