import {
  Row,
  Column,
  Spacer,
  Chip,
  Icon,
  Text
} from '@smu-chile/pkg-unimarc-components'

import { Image } from './Image'
import { Name } from './Name'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

interface ProductDetailsProps {
  name: string
  img: string
  isMobile?: boolean
  isNotAvailableProduct?: boolean
  leftQuantity?: number
}

export const ProductDetails = ({
  img,
  isMobile,
  name,
  isNotAvailableProduct,
  leftQuantity
}: ProductDetailsProps): React.ReactElement => {
  return (
    <Row
      alignItems='center'
      justifyContent='start'
    >
      <Column
        id={getBemId('shelfImage', 'container')}
        justifyContent='start'
        style={{
          maxWidth: 'max-content',
          opacity: isNotAvailableProduct ? 0.5 : 1
        }}
      >
        <Image
          altPicture={name}
          srcPicture={img}
        />
      </Column>
      <Spacer.Vertical customSize={isMobile ? 10 : 30} />
      <Column
        alignItems='start'
        maxWidth={isNotAvailableProduct ? '252px' : '180px'}
      >
        {isNotAvailableProduct && (
          <>
            <Row alignItems='center'>
              <Chip
                backgroundColor={getGlobalStyle('--color-neutral-gray-hover')}
                borderRadius={getGlobalStyle('--border-radius-lg')}
                color={getGlobalStyle('--color-base-black')}
                label='Pronto disponible'
                padding='4px'
              />
              <Spacer.Vertical size={2} />
              <Icon
                color={getGlobalStyle('--color-base-black')}
                customSize={12}
                name='AlertIcon'
              />
            </Row>
            <Spacer.Horizontal size={4} />
          </>
        )}
        <Name
          isNotAvailableProduct={isNotAvailableProduct}
          name={name}
        />
        {isNotAvailableProduct && (
          <>
            <Spacer.Horizontal size={4} />
            <Text
              color='gray'
              fontSize={isMobile ? 'xs' : 'md'}
            >
              {leftQuantity}
            </Text>
          </>
        )}
      </Column>
    </Row>
  )
}
