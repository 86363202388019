import { Container } from '@smu-chile/pkg-unimarc-components'
import { memo, useCallback, useRef } from 'react'
import {
  replaceStrings,
  useMobile,
  useUpdateQuantity
} from '@smu-chile/pkg-unimarc-hooks'
import {
  ICartItem,
  ICartItemIssue,
  ICartProduct
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { SwiperButton } from '../SwiperButton/SwiperButton'
import { BehaviorSwiper, Swiper } from './Swiper'
import { MemoShelf } from '../Body/ProductsTotalizers/Products/Shelves/Shelf'
import { ISimulationItem } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ISimulation'
import { NotAvailableShelves } from 'components/Body/ProductsTotalizers/Products/NotAvailableShelves'

interface HandleOnSubmit {
  item?: ICartItem
  quantity: number
}

interface SwiperWraperProps {
  id: string
  item?: ICartItem
  isLoading: boolean
  isLoadingByEvent: boolean
  isProductsWithIssues?: boolean
  isProducts?: boolean
  orderItem?: ICartItemIssue
  products?: ICartProduct | Record<string, never>
  productDiscount?: ISimulationItem
  setLimitOnProductAlert?: (value: boolean) => void
}

export const SwiperWraper = memo(
  ({
    id,
    item,
    isLoading,
    isLoadingByEvent,
    isProductsWithIssues = false,
    isProducts,
    orderItem,
    products,
    productDiscount,
    setLimitOnProductAlert
  }: SwiperWraperProps) => {
    const refSwiper = useRef<BehaviorSwiper>(null)
    const { handleAddTempItem } = useUpdateQuantity(true)
    const { innerWidth } = useMobile()
    const isMobile = innerWidth < 1280

    const handleDeleted = () => {
      handleAddTempItem({ quantity: 0, item })
    }

    const upateItem = ({ quantity, item }) => {
      if (quantity != item?.price?.quantity) {
        let totalQuantity = quantity
        if (isProducts) {
          products.items.forEach(({ description, price }) => {
            if (
              description?.id == item.description.id &&
              replaceStrings(price?.sellingPrice) !=
                replaceStrings(item?.price?.sellingPrice)
            ) {
              totalQuantity += price.quantity
            }
          })
        }
        handleAddTempItem({
          quantity: totalQuantity,
          item,
          netContent: item.description.netContent
        })
      }
    }

    const handleOnSubmit = useCallback(
      ({ item, quantity }: HandleOnSubmit) => {
        if (isMobile && !refSwiper.current.isOpen && quantity === 0) {
          refSwiper.current.open()
        } else if (isMobile && refSwiper.current.isOpen && quantity === 0) {
          refSwiper.current.closed()
        } else {
          upateItem({ quantity, item })
        }
        upateItem({ quantity, item })
      },
      [handleAddTempItem]
    )

    return (
      <Container>
        <Swiper
          buttonComponent={<SwiperButton onClick={handleDeleted} />}
          ref={refSwiper}
          swiper={isMobile}
        >
          {!isProductsWithIssues && (
            <MemoShelf
              bemId={id}
              handleOnSubmit={handleOnSubmit}
              isLoading={isLoading}
              isLoadingByEvent={isLoadingByEvent}
              isMobile={isMobile}
              item={item}
              productDiscount={productDiscount}
              setLimitOnProductAlert={setLimitOnProductAlert}
            />
          )}

          {isProductsWithIssues && orderItem && (
            <NotAvailableShelves
              bemId={id}
              isLoadingByEvent={isLoadingByEvent}
              isMobile={isMobile}
              orderItem={orderItem}
            />
          )}
        </Swiper>
      </Container>
    )
  }
)

SwiperWraper.displayName = 'SwiperWraper'
