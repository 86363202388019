import React, { useEffect, useState, memo, useCallback } from 'react'
import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ProductDetails } from './ProductDetails'
import { Image } from './ProductDetails/Image'
import { Name } from './ProductDetails/Name'
import { Price } from './ProductDetails/Price'
import {
  getValuesShelfCart,
  updateProductQuantity,
  useEvents,
  useTrigerEvent
} from '@smu-chile/pkg-unimarc-hooks'
import storageNotes from 'shared/utils/storageNotes/storageNotes'
import { ShelfDeleteItem } from './ShelfDeleteItem'
import { ICartItem } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { QuantityWrapper } from './QuantityWrapper/QuantityWrapper'
import { ISimulationItem } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ISimulation'
import { GrayLink } from './GrayLink'

interface HandleOnSubmit {
  quantity: number
  item: ICartItem
}

interface ShelfProps {
  bemId: string
  item: ICartItem
  isLoading: boolean
  isLoadingByEvent: boolean
  isMobile: boolean
  productDiscount?: ISimulationItem
  handleOnSubmit?: ({ quantity, item }: HandleOnSubmit) => void
  setLimitOnProductAlert: (value: boolean) => void
}

const Shelf = ({
  bemId,
  item: { description, price },
  isLoading,
  isLoadingByEvent,
  isMobile,
  productDiscount,
  handleOnSubmit,
  setLimitOnProductAlert
}: ShelfProps): React.ReactElement => {
  const { id } = description || {}
  const [quantity, setQuantity] = useState(price.quantity)
  const [updateFinished, setUpdateFinished] = useState(false)
  const [prevQuantity, setPrevQuantity] = useState(price.quantity)
  const [message, setMessage] = useState('')
  const [inputQuantity, setInputQuanity] = useState(false)
  const { trigger } = useTrigerEvent()
  const { showValue } = getValuesShelfCart({
    item: { description, price },
    quantity
  })
  const isCartLimit: boolean =
    price?.cartLimit > 0 && quantity >= price?.cartLimit
  const unitPrice = price?.sellingPrice
  const paddingSkeleton = isMobile ? '0' : '8px 0 0 0'
  const site = 'Unimarc'

  const hideBetterOption = true

  const updateProductQuantityTag = () => {
    if (quantity === prevQuantity) return

    updateProductQuantity({
      cartPrev: prevQuantity,
      cartRes: quantity,
      inputQuantity: inputQuantity,
      productId: description?.productId,
      site,
      listId: 'carrito',
      listName: 'carrito'
    })
    setPrevQuantity(quantity)
  }

  const sumQuantity = () => {
    setQuantity(quantity + 1)
  }

  const restQuantity = () => {
    if (isMobile && quantity - 1 === 0) {
      handleOnSubmit({ quantity: 0, item: { description, price } })
    } else {
      setQuantity(quantity - 1)
    }
  }

  const changeQuantity = (element) => {
    setInputQuanity(true)
    const elementVal = parseInt(element.target.value)
    setQuantity(elementVal)
  }

  const openNote = () => {
    trigger({
      eventType: 'productNote',
      data: {
        show: true,
        item: {
          brand: description?.brandName,
          id: description?.id,
          imageUrl: description?.image,
          measurement: description?.netContent,
          name: description?.name
        }
      }
    })
  }

  const deleteItem = useCallback(() => {
    if (!isMobile) {
      setQuantity(0)
    } else {
      handleOnSubmit({ quantity: 0, item: { description, price } })
    }
  }, [isMobile])

  const quantityToSet = (quantity: number): number => {
    if (price?.cartLimit > 0 && quantity > price?.cartLimit) {
      return price?.cartLimit
    }
    return quantity
  }

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail }) => {
      if (detail?.loading && detail?.items?.length) {
        const countItems = detail?.items.find((element) => {
          return element?.id == id
        })
        if (countItems) {
          updateProductQuantityTag()
          setQuantity(quantityToSet(countItems?.quantity))
          if (
            countItems?.cartLimit > 0 &&
            countItems?.quantity >= countItems?.cartLimit
          ) {
            setTimeout(() => {
              setLimitOnProductAlert(true)
            }, 3000)
          }
        }
      } else if (!detail?.loading) {
        updateProductQuantityTag()
        setInputQuanity(false)
        setQuantity(quantityToSet(quantity))
      }
    }
  })

  useEvents({
    eventType: 'productNote',
    callBack: ({ detail }) => {
      if (!detail?.show && detail?.item?.id === description?.id)
        setMessage(storageNotes.getNoteByProductId(description?.id))
    }
  })

  useEvents({
    eventType: 'updateQuantityDone',
    callBack: ({ detail: { finished } }) => {
      setUpdateFinished(finished)
    }
  })

  useEffect(() => {
    const maxQuantityByCartlimit =
      price?.cartLimit > 0 && quantity > price?.cartLimit
        ? price?.cartLimit
        : quantity
    handleOnSubmit({
      quantity: maxQuantityByCartlimit,
      item: { description, price }
    })
  }, [quantity])

  useEffect(() => {
    setUpdateFinished(false)
  }, [price?.quantity, updateFinished])

  useEffect(() => {
    setMessage(storageNotes.getNoteByProductId(description?.id))
  }, [])

  if (quantity == 0) {
    updateProductQuantityTag()
    return (
      <Column padding={paddingSkeleton}>
        <Row>
          <Backbone
            borderRadius={getGlobalStyle('--border-radius-md')}
            height={isMobile ? 108 : 140}
          />
        </Row>
        <Spacer.Horizontal size={16} />
      </Column>
    )
  }

  return (
    <>
      <BigScreen>
        <>
          <Container
            id={bemId}
            isWrap
            minWidth='100%'
            padding='12px 16px'
          >
            <Row>
              <ShelfDeleteItem
                isLoading={isLoadingByEvent}
                isMobile={false}
                onDelete={deleteItem}
              />
            </Row>
            <Spacer.Horizontal customSize={3} />
            <Row
              alignItems='center'
              backgroundColor={getGlobalStyle('--color-base-white')}
              customHeight='80px'
              justifyContent='between'
              maxHeight='80px'
              position='relative'
              tagName='article'
            >
              <Row alignItems='center'>
                {/* Product Details & Shelf */}
                <Column
                  maxWidth='290px'
                  minWidth='290px'
                >
                  <ProductDetails
                    img={description?.image}
                    name={description?.name}
                  />
                </Column>
                <Spacer.Vertical customSize={30} />
                {/* Price */}
                <Column
                  alignItems='start'
                  maxWidth='max-content'
                >
                  <Price
                    isLoading={isLoading}
                    price={unitPrice}
                    productDiscount={productDiscount?.price}
                  />
                </Column>
                <Spacer.Vertical customSize={30} />
              </Row>
              {/* Quantity Button */}
              <Column
                alignItems='end'
                justifyContent='center'
                maxHeight='100px'
                maxWidth='max-content'
                minHeight='100px'
              >
                <Row>
                  <Container maxWidth='max-content'>
                    <QuantityWrapper
                      changeQuantity={changeQuantity}
                      isCartLimit={isCartLimit}
                      isLoadingQuantity={isLoadingByEvent}
                      isMobile={isMobile}
                      price={price}
                      quantity={quantity}
                      restQuantity={restQuantity}
                      showValue={showValue}
                      sumQuantity={sumQuantity}
                    />
                  </Container>
                </Row>
              </Column>
            </Row>

            {/* extra options */}
            <Spacer.Horizontal size={8} />
            <Container minHeight='17px'>
              <GrayLink
                colorIcon={
                  isLoadingByEvent
                    ? getGlobalStyle('--color-primary-disabled')
                    : getGlobalStyle('--color-neutral-gray-dark')
                }
                colorText={
                  isLoadingByEvent
                    ? getGlobalStyle('--color-primary-disabled')
                    : getGlobalStyle('--color-neutral-gray-dark')
                }
                customIconSize={18}
                iconName={message ? 'MessageCheckIcon' : 'MessageLine'}
                id={getBemId('shelfComments', 'container')}
                label='Agregar comentario'
                onClick={openNote}
              />
              <Spacer.Vertical size={8} />
              {!hideBetterOption && (
                <GrayLink
                  colorIcon={
                    isLoadingByEvent
                      ? getGlobalStyle('--color-primary-disabled')
                      : getGlobalStyle('--color-neutral-gray-dark')
                  }
                  colorText={
                    isLoadingByEvent
                      ? getGlobalStyle('--color-primary-disabled')
                      : getGlobalStyle('--color-neutral-gray-dark')
                  }
                  customIconSize={18}
                  iconName='Cube3dIcon'
                  id={getBemId('shelfBestOption', 'container')}
                  label='Mejor opción'
                />
              )}
            </Container>
          </Container>
          <Spacer.Horizontal size={8} />
        </>
      </BigScreen>

      <SmallScreen>
        <>
          <Row
            alignItems='start'
            backgroundColor={getGlobalStyle('--color-base-white')}
            borderRadius={getGlobalStyle('--border-radius-md')}
            id={bemId}
            isWrap
            minWidth='100%'
            padding='8px'
            position='relative'
            tagName='article'
          >
            <Row
              alignItems='center'
              justifyContent='between'
              minHeight='67px'
              position='relative'
            >
              {/* Image [Mobile] */}
              <Row
                alignItems='center'
                minWidth='240px'
              >
                <Column
                  id={getBemId('shelfImage', 'container')}
                  maxWidth='50px'
                >
                  <Image
                    altPicture={description?.name}
                    srcPicture={description?.image}
                  />
                </Column>
                <Spacer.Vertical customSize={10} />
                <Column maxWidth='184px'>
                  {/* Name [Mobile] */}
                  <Name name={description?.name} />
                  <Spacer.Horizontal size={4} />
                  {/* Price [Mobile] */}
                  <Column>
                    <Price
                      isLoading={isLoading}
                      price={unitPrice}
                      productDiscount={productDiscount?.price}
                    />
                  </Column>
                </Column>
              </Row>
              {/* Quantity Button [Mobile] */}
              <QuantityWrapper
                changeQuantity={changeQuantity}
                isCartLimit={isCartLimit}
                isLoadingQuantity={isLoadingByEvent}
                isMobile={isMobile}
                price={price}
                quantity={quantity}
                restQuantity={restQuantity}
                showValue={showValue}
                sumQuantity={sumQuantity}
              />
            </Row>
            <Spacer.Horizontal size={8} />
            {/* extra options */}
            <Container
              alignItems='center'
              minHeight='17px'
            >
              <GrayLink
                colorIcon={
                  isLoadingByEvent
                    ? getGlobalStyle('--color-primary-disabled')
                    : getGlobalStyle('--color-neutral-gray-dark')
                }
                colorText={
                  isLoadingByEvent
                    ? getGlobalStyle('--color-primary-disabled')
                    : getGlobalStyle('--color-neutral-gray-dark')
                }
                customIconSize={15}
                iconName={message ? 'MessageCheckIcon' : 'MessageLine'}
                id={getBemId('shelfComments', 'container')}
                isMobile={true}
                label='Agregar comentario'
                onClick={openNote}
              />
              <Spacer.Vertical size={8} />
              {!hideBetterOption && (
                <GrayLink
                  colorIcon={
                    isLoadingByEvent
                      ? getGlobalStyle('--color-primary-disabled')
                      : getGlobalStyle('--color-neutral-gray-dark')
                  }
                  colorText={
                    isLoadingByEvent
                      ? getGlobalStyle('--color-primary-disabled')
                      : getGlobalStyle('--color-neutral-gray-dark')
                  }
                  customIconSize={15}
                  iconName='Cube3dIcon'
                  id={getBemId('shelfBestOption', 'container')}
                  isMobile={true}
                  label='Mejor opción'
                />
              )}
            </Container>
          </Row>
          <Spacer.Horizontal size={4} />
        </>
      </SmallScreen>
    </>
  )
}

const MemoShelf = memo(Shelf)

export { MemoShelf }
